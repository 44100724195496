import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import Avatar from '@atlaskit/avatar';
import AvatarItem from '@atlaskit/avatar/dist/cjs/components/AvatarItem';
import { useRecoilState } from 'recoil';
import UserDetailsAtom from '../../Recoil/Atoms/UserDetailsAtom';

export const MostActiveContibutor = props => {
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);
  // console.log('props', props);
  let rowarray = [];
  //creation of table syntax
  props.table_data.data.map((item, index) => {
    let id = item[props.table_data.userHeaderId];
    let cells = [
      {
        key: item['1'],
        content: (
          <div>
            <AvatarItem
              avatar={<Avatar size="small" src={userDetails[id]?.imageURL} />}
              primaryText={userDetails[id]?.displayName}
              enableTextTruncate="true"
            ></AvatarItem>
          </div>
        ),
        isSortable: true
      }
    ];
    rowarray.push({ cells: cells, key: index });
    return 0;
  });

  //head properties
  const head = {
    cells: [
      {
        key: 'Name',
        content: 'Name',
        isSortable: true
      }
    ]
  };

  return (
    <div>
      <h6 style={{ color: '#5E6C84', marginTop: '25px' }}>
        Most Active Contributors
      </h6>
      <DynamicTable
        head={head}
        rows={rowarray}
        isLoading={props.table_data.loading}
        emptyView={<h6>No matching records found</h6>}
      />
    </div>
  );
};
