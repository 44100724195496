import styled from 'styled-components';
import { borderRadius, colors, gridSize, math, themed } from '@atlaskit/theme';

export const ContentTabWrapper = styled.div`
  align-items: center;
  background-color: ${themed({ light: colors.N20, dark: colors.DN10 })};
  border-radius: ${borderRadius}px;
  color: ${colors.subtleText};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 4em;
  font-weight: 500;
  justify-content: center;
  margin-bottom: ${gridSize}px;
  margin-top: ${math.multiply(gridSize, 2)}px;
  padding: ${math.multiply(gridSize, 4)}px;
`;

export const AppContentWrapper = styled.div`
  padding: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  .content {
    margin-left: 5px;
  }
`;

export const AnalyzeWidgetWrapper = styled.div`
  .table-icon {
    background-image: url('/app/images/cells.svg');
    background-repeat: no-repeat;
    width: auto;
    height: 10px;
    display: block;
    margin: 8px 0 4px 3px;
    background-size: contain;
    background-size: contain;
    cursor: pointer;
  }
`;
