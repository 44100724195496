import React, { useEffect, useState } from 'react';
import { DatePicker } from '@atlaskit/datetime-picker';
import Dropdown, {
  DropdownItem,
  DropdownItemGroup
} from '@atlaskit/dropdown-menu';
import styles from './filter.module.css';
import { FilterTransform } from '../../Functional/Transfrom';
import {
  ContentCode,
  DisplayCode,
  DaterangeCode,
  GroupbyCode
} from '../../Functional/Strings';
import {
  applyViewFilter,
  getViewFilter,
  saveViewFilter
} from '../../API/filterApis';
import { useRecoilState } from 'recoil';
import FilterAtom from '../../Recoil/Atoms/FilterAtom';
import AccountAtom from '../../Recoil/Atoms/AccountAtom';
import axiosInstance from '../../API/axiosInstance';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';
import { mockGeneralgetdata } from '../../Mock/FakeData';
import Select from '@view26/select';
import { Button } from '@atlaskit/button/dist/cjs/components/Button';
import { datadogRum } from '@datadog/browser-rum';
import jwtDecode from 'jwt-decode';
import { getIframeBaseUrl } from '../../Functional/authUtils';

function Filterview(props) {
  const [filterData, setFilterData] = useRecoilState(FilterAtom);
  const [accountData, setAccountData] = useRecoilState(AccountAtom);
  const [viewData, setViewData] = useRecoilState(ViewDataAtom);

  const [validateDate, setValidateDate] = useState(false);
  const StartDate = filterData.start_date;
  const EndDate = filterData.end_date;
  const Contentvalue = filterData.content_value;
  const Displayvalue = filterData.display_value;
  const Groupbyvalue = filterData.group_by_value;
  const Sourcevalue = filterData.source_value;
  const Tab_index = viewData.tab_index;
  const General_page_flag = viewData.space_setting;
  const ContentArray = filterData.content_array;
  const SourceArray = filterData.source_array;
  const DisplayArray = filterData.display_array;
  const GroupbyArray = filterData.groupby_array;
  const isAuthenticated = accountData.is_authenticated;
  const Spacevalues = filterData.space_value;
  const SaveFlag = filterData.save_flag;
  const JWT = accountData.JWT;
  console.log('Checking filter state on renderre', filterData);
  //visiblity of the groupby and display
  let visiblestyle;
  if (Tab_index === 0) {
    visiblestyle = styles.visible;
  } else {
    visiblestyle = styles.invisible;
  }

  let sourceVisibleStyle;
  if (Tab_index !== 0) {
    sourceVisibleStyle = styles.visible;
  } else {
    sourceVisibleStyle = styles.invisible;
  }
  //for addding jwt header
  const setJWT = async (jwt = '') => {
    setAccountData(prev => ({ ...prev, JWT: jwt, is_authenticated: !!jwt }));
    if (jwt) {
      const decoded = jwtDecode(jwt);
      datadogRum.setGlobalContext('accID', decoded.accID);
      const baseUrl = await getIframeBaseUrl();
      if (baseUrl) {
        datadogRum.setGlobalContext('baseURL', baseUrl.origin);
      }
    }
  };

  const applyFilters = async tab_index => {
    let reqpayload, transformed;
    let response = {
      data: {},
      headers: {}
    };

    const setToken = () =>
      setAccountData(prev => ({
        ...prev,
        JWT: response.headers['authorization']
      }));

    switch (tab_index) {
      case 0:
        reqpayload = General_page_flag
          ? {
              selected: {
                [DaterangeCode]: {
                  label: 'Date Range',
                  type: 'range',
                  value: [StartDate, EndDate, 'Custom']
                },
                [ContentCode]: {
                  label: 'Content',
                  type: 'single',
                  value: [Contentvalue]
                },
                [DisplayCode]: {
                  label: 'Display',
                  type: 'single',
                  value: [Displayvalue]
                },
                [GroupbyCode]: {
                  label: 'Group By',
                  type: 'single',
                  value: [Groupbyvalue]
                },
                '-27': {
                  label: 'Spaces',
                  type: 'multilist',
                  value: [...Spacevalues]
                }
              }
            }
          : {
              selected: {
                [DaterangeCode]: {
                  label: 'Date Range',
                  type: 'range',
                  value: [StartDate, EndDate, 'Custom']
                },
                [ContentCode]: {
                  label: 'Content',
                  type: 'single',
                  value: [Contentvalue]
                },
                [DisplayCode]: {
                  label: 'Display',
                  type: 'single',
                  value: [Displayvalue]
                },
                [GroupbyCode]: {
                  label: 'Group By',
                  type: 'single',
                  value: [Groupbyvalue]
                }
              }
            };
        //Detect any change in filter values using hash compare
        setViewData(prev => ({
          ...prev,
          timeline_chart: { data: [], loading: true },
          active_contributores: { data: [], loading: true },
          active_reader: { data: [], loading: true },
          popular_content: { data: [], loading: true }
        }));

        response = await applyViewFilter(1, reqpayload, JWT);
        // console.log('checking responsei in filter view', response);
        transformed = FilterTransform(response.data, tab_index);

        setViewData(prev => ({
          ...prev,
          timeline_chart: {
            data: transformed.Chart,
            loading: false
          },
          active_contributores: transformed.ActiveContri,
          active_reader: transformed.ActiveReader,
          popular_content: transformed.PopularContent
        }));
        setToken();

        break;
      //pageview
      case 1:
        reqpayload = General_page_flag
          ? {
              selected: {
                [DaterangeCode]: {
                  label: 'Date Range',
                  type: 'range',
                  value: [StartDate, EndDate, 'Custom']
                },
                [ContentCode]: {
                  label: 'Content',
                  type: 'single',
                  value: [Contentvalue]
                },
                '-27': {
                  label: 'Spaces',
                  type: 'multilist',
                  value: [...Spacevalues]
                },
                '-29': {
                  label: 'Source',
                  type: 'single',
                  value: [Sourcevalue]
                }
              }
            }
          : {
              selected: {
                [DaterangeCode]: {
                  label: 'Date Range',
                  type: 'range',
                  value: [StartDate, EndDate, 'Custom']
                },
                [ContentCode]: {
                  label: 'Content',
                  type: 'single',
                  value: [Contentvalue]
                },
                '-29': {
                  label: 'Source',
                  type: 'single',
                  value: [Sourcevalue]
                }
              }
            };
        //Detect any change in filter values using hash compare

        setViewData(prev => ({
          ...prev,
          page_view_table: { data: [], loading: true }
        }));

        response = await applyViewFilter(2, reqpayload, JWT);

        // console.log('checking responsei2 in filter view', response);

        props.setJsmAgentInstalled(
          response.data[5].viewPointData?.jsmAgentInstalled
        );

        // props.setJsmAgentInstalled(true)

        transformed = FilterTransform(response.data, tab_index);
        setViewData(prev => ({
          ...prev,
          page_view_table: {
            data: transformed.Table,
            userHeaderId: transformed.pageviewTableId,
            loading: false
          }
        }));
        setToken();

        break;
      case 2:
        //userview
        reqpayload = General_page_flag
          ? {
              selected: {
                [DaterangeCode]: {
                  label: 'Date Range',
                  type: 'range',
                  value: [StartDate, EndDate, 'Custom']
                },
                [ContentCode]: {
                  label: 'Content',
                  type: 'single',
                  value: [Contentvalue]
                },
                '-27': {
                  label: 'Spaces',
                  type: 'multilist',
                  value: [...Spacevalues]
                },
                '-29': {
                  label: 'Source',
                  type: 'single',
                  value: [Sourcevalue]
                }
              }
            }
          : {
              selected: {
                [DaterangeCode]: {
                  label: 'Date Range',
                  type: 'range',
                  value: [StartDate, EndDate, 'Custom']
                },
                [ContentCode]: {
                  label: 'Content',
                  type: 'single',
                  value: [Contentvalue]
                },
                '-29': {
                  label: 'Source',
                  type: 'single',
                  value: [Sourcevalue]
                }
              }
            };
        //Detect any change in filter values using hash compare
        setViewData(prev => ({
          ...prev,
          user_view_table: { data: [], loading: true }
        }));

        response = await applyViewFilter(3, reqpayload, JWT);
        // console.log('checking responsei2 in filter view', response);

        props.setJsmAgentInstalled(
          response.data[6].viewPointData?.jsmAgentInstalled
        );

        // props.setJsmAgentInstalled(true)

        // Check the conditions and update showDataFlag

        transformed = FilterTransform(response.data, tab_index);

        setViewData(prev => ({
          ...prev,
          user_view_table: {
            data: transformed.UserTable,
            userHeaderId: transformed.UserTableId,
            loading: false
          }
        }));

        setToken();

        break;
      default:
        break;
    }
  };
  const getInitialSettings = async () => {
    let response;
    if (process.env.NODE_ENV === 'production') {
      response = await getViewFilter(1, JWT);
    } else {
      response = mockGeneralgetdata;
    }
    // console.log(
    //   'getInitialsettings in useeffect',
    //   response,
    //   response.data.selected?.['-29']?.value[0]
    // );
    setFilterData(prev => ({
      ...prev,
      display_value: response.data.selected?.['-28']?.value[0],
      content_value: response.data.selected?.['-26']?.value[0],
      space_value: response.data.selected['-27']
        ? [...response.data.selected?.['-27']?.value]
        : [],
      source_value: response.data.selected?.['-29']?.value[0],
      group_by_value: response.data.selected?.['-3']?.value[0]
    }));
    if (response.data.config['-27']) {
      setViewData(prev => ({
        ...prev,
        spaces_list: [...response.data.config?.['-27']?.value]
      }));
    }
    setAccountData(prev => ({
      ...prev,
      JWT: response.headers['authorization']
    }));
  };
  useEffect(() => {
    //immediate function for checking the enviorenment and checking the auth methodolgy used
    //check the enivonment
    console.log('inital useeffect for the filter view');
    if (process.env.NODE_ENV === 'production' && isAuthenticated === false) {
      let meta = document.getElementById('jwt');
      //if meta jwt is not present in the index.html
      if (!meta) {
        //AP call for gettig context space key
        window.AP.context.getContext(context => {
          //Ap call for geting jwt token
          window.AP.context.getToken(async token => {
            //API for auth
            if (context.confluence) {
              let res = await axiosInstance.post(
                `/authorize?jwt=${token}&spaceKey=${context.confluence.space.key}&lic=active`
              );
              // console.log('Successful token req', token, res, context);
              setJWT(res.headers.authorization);
            } else {
              // console.error('Failed Sending jwt got window api call', err, context);
              let res = await axiosInstance.post(
                `/authorize?jwt=${token}&spaceKey=&lic=active`
              );
              setJWT(res.headers.authorization);
              setViewData(prev => ({
                ...prev,
                space_setting: true
              }));
            }
          });
        });
      } //if meta jwt is present
      else {
        let jwt = meta.content;
        setJWT(jwt);
      }
      //dev instance
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log('getinitalview call');
      if (isAuthenticated && props.firstLoadFlag?.current) {
        getInitialSettings();
        props.firstLoadFlag.current = false;
      }
    } else {
      if (props.firstLoadFlag?.current) {
        getInitialSettings();
        props.firstLoadFlag.current = false;
      }
    }
  }, [isAuthenticated]);
  useEffect(() => {
    // if (isAuthenticated) {
    //   applyFilters(Tab_index);
    // }
    if (process.env.NODE_ENV === 'production') {
      if (isAuthenticated) {
        applyFilters(Tab_index);
      }
    } else {
      applyFilters(Tab_index);
    }
  }, [
    Contentvalue,
    Displayvalue,
    Groupbyvalue,
    StartDate,
    EndDate,
    Spacevalues,
    Sourcevalue
  ]);

  //Change Functions for filter values
  const changeStartDate = value => {
    //compare the startdate and end date for validation
    let comparedate = new Date(EndDate);
    let currentdate = new Date(value);
    if (currentdate.getTime() < comparedate.getTime()) {
      setFilterData(prev => ({
        ...prev,
        start_date: value,
        save_flag: true
      }));
      setValidateDate(false);
    } else {
      setValidateDate(true);
    }
  };

  const changeEndDate = value => {
    //compare the startdate and end date for validation
    let comparedate = new Date(StartDate);
    let currentdate = new Date(value);
    if (currentdate.getTime() > comparedate.getTime()) {
      setFilterData(prev => ({
        ...prev,
        end_date: value,
        save_flag: true
      }));
      setValidateDate(false);
    } else {
      setValidateDate(true);
    }
  };

  const changeContent = value =>
    setFilterData(prev => ({
      ...prev,
      content_value: value,
      save_flag: true
    }));

  const changeSource = value =>
    setFilterData(prev => ({
      ...prev,
      source_value: value,
      save_flag: true
    }));

  const changeGroupBy = value =>
    setFilterData(prev => ({
      ...prev,
      group_by_value: value,
      save_flag: true
    }));

  const changeDisplay = value =>
    setFilterData(prev => ({
      ...prev,
      display_value: value,
      save_flag: true
    }));

  const optionsconverter = values => {
    let structuresvalues = values.map((item, i) => {
      return {
        id: i,
        value: item,
        label: item,
        group: ''
      };
    });
    return structuresvalues;
  };
  const onSpaceChange = values => {
    // console.log("slecet component values", values)
    let selectedspaces = values.map(elem => {
      return elem.value;
    });
    setFilterData(prev => ({
      ...prev,
      space_value: [...selectedspaces],
      save_flag: true
    }));
  };
  const onSaveClick = async values => {
    console.log('filter data for saving is', filterData);
    let filtertopost = {
      selected: {
        [DaterangeCode]: {
          label: 'Date Range',
          type: 'range',
          value: [StartDate, EndDate, 'Custom']
        },
        [ContentCode]: {
          label: 'Content',
          type: 'single',
          value: [filterData.content_value]
        },
        [DisplayCode]: {
          label: 'Display',
          type: 'single',
          value: [filterData.display_value]
        },
        [GroupbyCode]: {
          label: 'Group By',
          type: 'single',
          value: [filterData.group_by_value]
        },
        '-27': {
          label: 'Spaces',
          type: 'multilist',
          value: [...Spacevalues]
        },
        '-29': {
          label: 'Source',
          type: 'single',
          value: [filterData.source_value]
        }
      }
    };
    let res = saveViewFilter(filtertopost, JWT);
    // console.log("result from api", res);
    setFilterData(prev => ({ ...prev, save_flag: false }));
  };

  return (
    <div>
      <div className={styles.Whole}>
        {General_page_flag && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '1px',
              flexWrap: 'wrap',
              alignContent: 'space-between'
            }}
          >
            <div className={styles.paragraph}>Spaces</div>
            <div className={styles.components}>
              <Select
                options={optionsconverter(viewData.spaces_list)}
                isMulti={true}
                selected={optionsconverter(filterData.space_value)}
                onChange={onSpaceChange}
                label="Spaces"
              />
            </div>
          </div>
        )}
        <div className={styles.paragraph}>DateRange</div>
        <div className={styles.datecomponents}>
          <DatePicker
            isInvalid={validateDate}
            value={StartDate}
            placeholder="e.g. 2018/01/01"
            spacing="compact"
            //icon= "@atlaskit/icon/glyph/calendar.CalendarIcon"
            onChange={new_Date => changeStartDate(new_Date)}
            // appearance="subtle"
          />
        </div>
        <div style={{ paddingTop: '10px' }}>-</div>
        <div className={styles.datecomponents}>
          <DatePicker
            isInvalid={validateDate}
            data-testid="Datepicker2"
            value={EndDate}
            placeholder="e.g. 2018/01/01"
            spacing="compact"
            //appearance="subtle"
            //icon={CalendarIcon}
            onChange={new_Date => changeEndDate(new_Date)}
          />
        </div>

        <div className={styles.visible}>
          <p className={styles.paragraph}>Content</p>
          <div className={styles.components}>
            <Dropdown
              trigger={Contentvalue}
              style={{ alignSelf: 'center', marginLeft: '20px' }}
              boundariesElement="window"
              shouldFitContainer="false"
              triggerType="button"
              //triggerButtonProps={{appearance:"subtle"}}
            >
              <DropdownItemGroup title="Options">
                {ContentArray.map(item => (
                  <DropdownItem key={item} onClick={() => changeContent(item)}>
                    {item}
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </Dropdown>
          </div>
        </div>
        <div className={sourceVisibleStyle}>
          <p className={styles.paragraph}>Source</p>
          <div className={styles.components}>
            <Dropdown
              trigger={Sourcevalue}
              style={{ alignSelf: 'center', marginLeft: '20px' }}
              boundariesElement="window"
              shouldFitContainer="false"
              triggerType="button"
              //triggerButtonProps={{appearance:"subtle"}}
            >
              <DropdownItemGroup title="Options">
                {SourceArray.map(item => (
                  <DropdownItem key={item} onClick={() => changeSource(item)}>
                    {item}
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </Dropdown>
          </div>
        </div>
        <div className={visiblestyle}>
          <p className={styles.paragraph}>Groupby</p>
          <div className={styles.components}>
            <Dropdown
              trigger={Groupbyvalue}
              shouldFitContainer="false"
              triggerType="button"
              //triggerButtonProps={{appearance:"subtle"}}
            >
              <DropdownItemGroup>
                {GroupbyArray.map(item => (
                  <DropdownItem
                    key={item}
                    //key={item}
                    onClick={() => changeGroupBy(item)}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </Dropdown>
          </div>
        </div>
        <div className={visiblestyle}>
          <p className={styles.paragraph}>Display</p>
          <div className={styles.components}>
            <Dropdown
              trigger={Displayvalue}
              triggerType="button"
              //triggerButtonProps={{appearance:"subtle"}}
              style={{ alignItems: 'stretch' }}
            >
              <DropdownItemGroup>
                {DisplayArray.map(item => (
                  <DropdownItem key={item} onClick={() => changeDisplay(item)}>
                    {item}
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </Dropdown>
          </div>
        </div>
        {General_page_flag && SaveFlag && (
          <div>
            <div className={styles.components}>
              <Button appearance="link" onClick={onSaveClick}>
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Filterview;
