import React from 'react';
import { createHistory, LocationProvider, Router } from '@reach/router';
import { Home } from '../Home';
import createHashSource from 'hash-source';
const Settings = React.lazy(() => import('../../OtherPages/Settings/Settings'));
const PermissionPage = React.lazy(() =>
  import('../../OtherPages/PermissionPage/PermissionPage')
);
const source = createHashSource();
const history = createHistory(source);

const AppContent = () => {
  return (
    <div className="ac-content">
      <LocationProvider history={history}>
        <Router>
          <Home path="/" />
          <Settings path="/settings" />
          <PermissionPage path="/dashboard/settings" />
        </Router>
      </LocationProvider>
    </div>
  );
};

export default AppContent;
