import React, { useEffect, useState } from 'react';
import Filterview from '../FilterComponent/FilterView';
import styles from './Users.module.css';
import { UserSearch } from './UserSearch';
import { useRecoilState } from 'recoil';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';
import { getUserDetails } from '../ApCalls';
import UserDetailsAtom from '../../Recoil/Atoms/UserDetailsAtom';
import FilterAtom from '../../Recoil/Atoms/FilterAtom';
export const User = () => {
  const [viewData] = useRecoilState(ViewDataAtom);
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);
  const [jsmAgentInstalled, setJsmAgentInstalled] = useState('');
  const [filterData] = useRecoilState(FilterAtom);
  const UserViewTable = viewData.user_view_table;
  let users = [];
  const Sourcevalue = filterData.source_value;
  useEffect(() => {
    users.forEach(user => {
      if (user !== '0' && Sourcevalue !== 'JSM Customer Portal') {
        fetchUserDetails(user);
      } else if (user === '0') {
        setUserDetails(prev => ({
          [user]: {
            imageURL: '',
            displayName: 'Anonymous'
          },
          ...prev
        }));
      }
      //  else if (user === '0') {
      //   setUserDetails(prev => ({
      //     [user]: {
      //       imageURL: '',
      //       displayName: 'Anonymous'
      //     },
      //     ...prev
      //   }));
      // }
    });
  }, [UserViewTable.loading]);

  UserViewTable.data.forEach(item => {
    users.push(item[UserViewTable.userHeaderId]);
  });
  async function fetchUserDetails(userId) {
    const resp = await getUserDetails(userId);
    const baseURL = resp?._links?.base.slice(0, -5);
    const pathURL = resp?.profilePicture?.path;
    const imageURL = baseURL + pathURL;
    const displayName = resp?.displayName;
    // userDetails[userId] = imageURL
    setUserDetails(prev => ({
      [userId]: {
        imageURL: imageURL,
        displayName: displayName
      },
      ...prev
    }));
  }

  return (
    <div className={styles.whole}>
      <Filterview setJsmAgentInstalled={setJsmAgentInstalled} />
      <div className={styles.components}>
        <h6 className={styles.components} style={{ color: '#5E6C84' }}>
          USERS
        </h6>

        <UserSearch
          tabledata={UserViewTable}
          jsmAgentInstalled={jsmAgentInstalled}
        />
      </div>
    </div>
  );
};
