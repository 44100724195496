import React, { useState, useEffect } from 'react';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import { UsersTable } from './UserTable';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Dialogscreen } from '../Dialogscreen';
//use this only for dev
//import { fakeUserDialog } from '../fake';
import SearchIcon from '@atlaskit/icon/glyph/editor/search';
import {
  UserFilterfunction,
  UserDialogFilterfunction
} from '../../Functional/FilterFunctions';
import { createUserTableSyntax } from '../../Functional/TableSyntax';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import { user_xlsx_array_create } from '../../Functional/Download';
import { useRecoilState } from 'recoil';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';
import AccountAtom from '../../Recoil/Atoms/AccountAtom';
import FilterAtom from '../../Recoil/Atoms/FilterAtom';
import { fetchDataView } from '../../API/dataViewApis';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import UserDetailsAtom from '../../Recoil/Atoms/UserDetailsAtom';
import jwtDecode from 'jwt-decode';
import SectionMessage, {
  SectionMessageAction
} from '@atlaskit/section-message';

export const UserSearch = props => {
  const [viewData, setViewData] = useRecoilState(ViewDataAtom);
  const [accountData, setAccountData] = useRecoilState(AccountAtom);
  const [filterData] = useRecoilState(FilterAtom);
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);
  const [dialogData, setDialogData] = useState({
    users: [],
    id: props.tabledata.userHeaderId
  });
  const [colId, setColId] = useState(0);

  const UserDialog = viewData.user_dialog;
  const StartDate = filterData.start_date;
  const EndDate = filterData.end_date;
  const Contentvalue = filterData.content_value;
  const Sourcevalue = filterData.source_value;
  const DialogLoading = viewData.is_dialog_loading;
  const JWT = accountData.JWT;
  const [searchkeyword, setSearchKeyword] = useState('');
  const [ModalState, setModalState] = useState('');
  const [Dialogsearchstate, setDialogSearchKeyword] = useState('');
  const [header, setheader] = useState('');
  const [showDataFlag, setShowDataFlag] = useState(false);

  const decoded = jwtDecode(JWT);
  let jwtBaseUrl = decoded?.baseURL;
  let baseURL = jwtBaseUrl?.slice(0, -5);

  const dialogSearch = value => {
    setDialogSearchKeyword(value);
  };

  // dialog search function which takes orginal table and search keyword as parameters
  let dialogFilterTableValue = UserDialogFilterfunction(
    UserDialog || {},
    Dialogsearchstate
  );

  // console.log('FILTERED USER', dialogFilterTableValue);

  //api call in dialog box
  const buttonClick = async (value, id) => {
    setViewData(prev => ({
      ...prev,
      is_dialog_loading: true
    }));
    setModalState(1);
    setColId(id);
    let reqpayload = {
      selected: {
        '-2': {
          label: 'Date Range',
          type: 'range',
          value: [StartDate, EndDate, 'Custom']
        },
        '-26': {
          label: 'Content',
          type: 'single',
          value: [Contentvalue]
        },

        '-29': {
          label: 'Source',
          type: 'single',
          value: [Sourcevalue]
        }
      }
    };
    let reqquery = 'columnID=' + id + '&id=6&rowID=' + value;
    //ApiCall(reqpayload,PageviewDialogtransform)

    const response = await fetchDataView(reqquery, reqpayload, accountData.JWT);
    if (response.success) {
      setAccountData(prev => ({
        ...prev,
        JWT: response.headers.authorization
      }));

      setViewData(prev => ({
        ...prev,
        user_dialog: response.data.data,
        is_dialog_loading: false
      }));

      //different header for different modal box tables
      if (id == 3) {
        setheader({
          head: {
            cells: [
              {
                key: 'PAGES',
                content: 'Pages'
              },
              {
                key: 'CREATED',
                content: 'Created'
              }
            ]
          }
        });
      } else if (id == 4) {
        setheader({
          head: {
            cells: [
              {
                key: 'PAGES',
                content: 'Pages'
              },
              {
                key: 'UPDATED',
                content: 'Last Updated'
              }
            ]
          }
        });
      } else if (id == 5 && Sourcevalue === 'Confluence') {
        setheader({
          head: {
            cells: [
              {
                key: 'PAGES',
                content: 'Pages'
              },
              {
                key: 'VIEWED',
                content: 'Last Viewed'
              }
            ]
          }
        });
      } else if (id == 5 && Sourcevalue === 'JSM Customer Portal') {
        setheader({
          head: {
            cells: [
              {
                key: 'PAGES',
                content: 'Pages'
              },
              {
                key: 'VIEWED',
                content: 'Last Viewed'
              },
              {
                key: 'REQUEST TYPE',
                content: 'Request Type'
              },
              {
                key: 'REQUEST KEY',
                content: 'Request Key'
              }
            ]
          }
        });
      } else if (id == 7) {
        setheader({
          head: {
            cells: [
              {
                key: 'PAGES',
                content: 'Pages'
              },
              {
                key: 'REQUEST TYPE',
                content: 'Request Type'
              },
              {
                key: 'REQUEST KEY',
                content: 'Request Key'
              }
            ]
          }
        });
      }
      return;
    }
  };

  //modal close function
  const closeClick = value => {
    setModalState('');
  };

  const actions = [{ text: 'Close', onClick: closeClick }];

  //usertable search function

  let filteredTableValue = UserFilterfunction(
    props.tabledata.data,
    searchkeyword,
    userDetails
  );

  //create table syntax function that takes table data and button click function for opening corresponding dialog box

  let TableSyntax = createUserTableSyntax(
    filteredTableValue,
    buttonClick,
    userDetails,
    props.tabledata.userHeaderId,
    Sourcevalue
  );

  // useEffect(() => {

  //   if (
  //     Sourcevalue === 'JSM Customer Portal' &&
  //     props.jsmAgentInstalled === true
  //   ) {

  //     setShowDataFlag(true);
  //     console.log("inside 1st")
  //   } else if (Sourcevalue === 'Confluence') {

  //     setShowDataFlag(true);
  //     console.log("inside 2nd")
  //   }
  //   else if(Sourcevalue === 'JSM Customer Portal' &&
  // props.jsmAgentInstalled === false) {
  //   console.log("inside 3rd")
  //   setShowDataFlag(false);
  // }
  // }, [Sourcevalue])

  // console.log("DATA FLAG", showDataFlag)

  const clearSearch = () => setSearchKeyword('');
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Textfield
          name="search"
          placeholder="Contains text"
          width="xlarge"
          elemBeforeInput={
            <div style={{ paddingLeft: '6px', lineHeight: '100%' }}>
              <SearchIcon />
            </div>
          }
          elemAfterInput={
            <div
              onClick={clearSearch}
              style={{
                padding: '0 6px',
                lineHeight: '100%',
                cursor: 'pointer'
              }}
            >
              {searchkeyword.trim() && <CrossCircleIcon primaryColor="grey" />}
            </div>
          }
          value={searchkeyword}
          onChange={e => setSearchKeyword(e.target.value)}
          autoFocus={true}
        />
        <Button
          onClick={() => {
            user_xlsx_array_create(filteredTableValue, userDetails);
          }}
          iconBefore={<ArrowDownIcon />}
        ></Button>
      </div>
      {((Sourcevalue === 'JSM Customer Portal' &&
        props.jsmAgentInstalled === true) ||
        Sourcevalue === 'Confluence') && (
        <>
          <div style={{ padding: '10px 0' }}>
            <h6 style={{ textTransform: 'capitalize', color: '#919aaa' }}>
              {TableSyntax.length} Records
            </h6>
          </div>
          <ModalTransition>
            {ModalState && (
              <Modal
                actions={actions}
                onClose={closeClick}
                heading="Drill-down Data "
              >
                <Dialogscreen
                  table={header}
                  rows={dialogFilterTableValue}
                  searchfunction={dialogSearch}
                  search={Dialogsearchstate}
                  id={2}
                  colId={colId}
                  loading={DialogLoading}
                  dialogData={dialogData}
                  Sourcevalue={Sourcevalue}
                  baseURL={baseURL}
                />
              </Modal>
            )}
          </ModalTransition>
          <UsersTable
            values={TableSyntax}
            loading={props.tabledata.loading}
            // showDataFlag={showDataFlag}
          />
        </>
      )}
      {Sourcevalue === 'JSM Customer Portal' &&
        props.jsmAgentInstalled === false && (
          <div style={{ paddingTop: '40px' }}>
            <SectionMessage
              title="Missing Companion App"
              actions={[
                <SectionMessageAction href="#">
                  Install App
                </SectionMessageAction>
              ]}
            >
              <p>
                To access page-views for the JSM knowledge-base articles, you
                must install a JSM companion App available on the Atlassian
                Marketplace for free.
              </p>
            </SectionMessage>
          </div>
        )}
    </div>
  );
};
