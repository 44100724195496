import React, { useEffect, useRef } from 'react';
// import chartPlus from '../Chartplus/chartPlus';
import '@view26/chartplus';
import '@view26/chartplus/build/chartPlus.css';
import { ChartSyntax, GeneralChartSyntax } from '../../Functional/TableSyntax';
import { useRecoilState } from 'recoil';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';
import FilterAtom from '../../Recoil/Atoms/FilterAtom';
import { useState } from 'react';
import { timeFormat, timeParse } from 'd3-time-format';

//d3 blackbox component
export const Chartplusbox = ({ openDrilldownView }) => {
  const [viewData] = useRecoilState(ViewDataAtom);
  const [filterData] = useRecoilState(FilterAtom);
  const [chartInstance, setChart] = useState();
  const TimelineChart = viewData.timeline_chart;
  // console.log('in chartplusbox checking the viewatom', viewData);
  // const value = viewData.space_setting? GeneralChartSyntax(TimelineChart.data): ChartSyntax(TimelineChart.data);
  const Groupbyvalue = filterData.group_by_value;
  const value = GeneralChartSyntax(TimelineChart.data, Groupbyvalue);
  const chart_flag = viewData.chart_flag;
  const containerRef = useRef();
  let monthFormat = timeFormat('%Y-%m');
  let weekFormat = timeFormat('%Y-%W');

  useEffect(() => {
    let svg = document.getElementById('container');
    value.container = svg;
    if (svg.childElementCount === 0) {
      // let chart = chartPlus(value, svg);
      let chart = new window.chartPlus.chart(value);

      setChart(chart);
    } else {
      svg.innerHTML = '';
      // chartPlus(value, svg);
      new window.chartPlus.chart(value);
    }
  }, [chart_flag]);

  useEffect(() => {
    const containerNode = containerRef.current;
    const handleTooltipClose = e => {
      openDrilldownView(prev => {
        return { ...prev, isactive: false };
      });
    };
    // Add event listener
    containerNode.addEventListener('tooltip-closed', handleTooltipClose);
    // Remove event listener on cleanup
    return () =>
      containerNode.removeEventListener('tooltip-closed', handleTooltipClose);
  }, []);
  useEffect(() => {
    const containerNode = containerRef.current;
    const handleDataView = e => {
      const dataViewArgs = {};
      const granularity = 'test';
      if (granularity) dataViewArgs['granularity'] = granularity;
      let intXValue = e.data.category;
      // console.log('in chartplus box event passing the date as', intXValue);
      let parts = intXValue.split('-');
      if (Groupbyvalue === 'Month') {
        // intXValue = monthFormat(new Date(parts[0], parts[1] - 1, parts[2]));
        intXValue = monthFormat(Date.parse(intXValue));
      }
      if (Groupbyvalue === 'Week') {
        // intXValue = weekFormat(new Date(parts[0], parts[1] - 1, parts[2]))
        intXValue = weekFormat(Date.parse(intXValue));
      }

      openDrilldownView(prev => {
        return {
          ...prev,
          granularity: granularity,
          xValue: intXValue,
          yValue: e.data.legend,
          isactive: true
        };
      });
    };

    // Add event listener
    containerNode.addEventListener('dataView', handleDataView);
    containerNode.addEventListener('Cell-Explorer', handleDataView);
    // Remove event listener on cleanup
    return () => {
      containerNode.removeEventListener('dataView', handleDataView);
      containerNode.removeEventListener('Cell-Explorer', handleDataView);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (chartInstance) chartInstance.destroy();
    };
  }, [chartInstance]);

  return (
    <div
      ref={containerRef}
      style={{ maxWidth: '900px', height: '300px' }}
      id="container"
    />
  );
};
