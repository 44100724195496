import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import {
  PAGEVIEWTABLEHEADING,
  PAGEVIEWJSMTABLEHEADING
} from '../../Functional/Strings';
import { useRecoilState } from 'recoil';
import FilterAtom from '../../Recoil/Atoms/FilterAtom';

export const PageViewTable = props => {
  const [filterData, setFilterData] = useRecoilState(FilterAtom);
  const Sourcevalue = filterData.source_value;
  return (
    <div style={{ margin: '6px' }}>
      <DynamicTable
        head={
          Sourcevalue === 'Confluence'
            ? PAGEVIEWTABLEHEADING.head
            : PAGEVIEWJSMTABLEHEADING.head
        }
        rows={props.values}
        rowsPerPage={10}
        page={1}
        isFixedSize
        isLoading={props.loading}
        emptyView={
          Sourcevalue === 'Confluence' ? (
            <h6>No matching records found</h6>
          ) : (
            <h6>No page views registered with the specified filter.</h6>
          )
        }
      />
    </div>
  );
};
