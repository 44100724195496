import React, { Suspense, useState, useCallback, useEffect } from 'react';
import { MostReaderTable } from './MostReaderTable';
import { MostPopularContent } from './MostPopularContent';
import { MostActiveContibutor } from './MostActiveContributor';
import FilterView from '../FilterComponent/FilterView';
import styles from './Overview.module.css';
import Spinner from '@atlaskit/spinner';
import ErrorBoundary from '../ErrorHandling/ErrorBoundary';
import { Chartplusbox } from '../Chartplus/Chartplusbox';
import { useRecoilState } from 'recoil';
import ViewDataAtom from '../../Recoil/Atoms/ViewDataAtom';
import { FullDataviewExcelsheet } from '../../Functional/Excelsheetconverter';
import FullDataView from './FullDataView';
import Button from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition
} from '@atlaskit/modal-dialog';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup
} from '@atlaskit/dropdown-menu';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { N500 } from '@atlaskit/theme/colors';
import MediaServicesPdfIcon from '@atlaskit/icon/glyph/media-services/pdf';
import { AnalyzeWidgetWrapper } from '../../Styles/wrappers';
import { getUserDetails } from '../ApCalls';
import UserDetailsAtom from '../../Recoil/Atoms/UserDetailsAtom';
//const Line = React.lazy(() => import('./Chart/Line'));

const Overview = props => {
  const [viewData] = useRecoilState(ViewDataAtom);
  const [userDetails, setUserDetails] = useRecoilState(UserDetailsAtom);
  const [drillDownData, setDrillDownData] = useState({
    xValue: '',
    yValue: '',
    granularity: '',
    isactive: false
  });

  const TimelineChart = viewData.timeline_chart;
  const PopularContent = viewData.popular_content;
  const ActiveContributores = viewData.active_contributores;
  const ActiveReader = viewData.active_reader;
  const isOpen = drillDownData.isactive;
  // const [users,setUsers] = useState([])
  let users = [];

  useEffect(() => {
    users.forEach(user => {
      if (user !== '0') {
        fetchUserDetails(user);
      } else if (user === '0') {
        setUserDetails(prev => ({
          [user]: {
            imageURL: '',
            displayName: 'Anonymous'
          },
          ...prev
        }));
      }
    });
  }, [ActiveReader.loading, ActiveContributores.loading]);

  ActiveReader.data.forEach(item => {
    users.push(item[ActiveReader.userHeaderId]);
    // setUsers(prev=>[...prev,item['0']])
  });

  ActiveContributores.data.forEach(item => {
    let flag = true;
    users.forEach(user => {
      if (user === item[ActiveContributores.userHeaderId]) {
        flag = false;
      }
    });
    if (flag) {
      users.push(item[ActiveContributores.userHeaderId]);
      // setUsers(prev=>[...prev,item['0']])
    }
  });
  async function fetchUserDetails(userId) {
    const resp = await getUserDetails(userId);
    const baseURL = resp?._links?.base.slice(0, -5);
    const pathURL = resp?.profilePicture?.path;
    const imageURL = baseURL + pathURL;
    const displayName = resp?.displayName;
    // userDetails[userId] = imageURL
    setUserDetails(prev => ({
      [userId]: {
        imageURL: imageURL,
        displayName: displayName
      },
      ...prev
    }));
  }

  function exceldownload() {
    let data = TimelineChart.data;
    let header = [];
    header.push({
      key: 'Datekey',
      content: 'Date',
      isSortable: true,
      setColor: false,
      width: 40
    });
    data.legends.forEach((elem, i) => {
      header.push({
        key: `key${i}`,
        content: elem,
        isSortable: true,
        setColor: false,
        width: 20
      });
    });
    let rows = data.data.map((item, i) => {
      let cell = header.map(elem => {
        if (elem.key === 'Datekey') {
          return {
            key: item.key,
            content: item.key
          };
        } else {
          return {
            key: item.values[elem.content]
              ? item.values[elem.content].split('.')[0]
              : '0' + i,
            content: item.values[elem.content]
              ? item.values[elem.content].split('.')[0]
              : '0'
          };
        }
      });
      return { cells: cell };
    });
    FullDataviewExcelsheet(rows, header, 'TimeLine');
  }
  function Modalclose() {
    setDrillDownData(prev => {
      return { ...prev, isactive: false };
    });
  }

  return (
    <div className={styles.whole}>
      <FilterView firstLoadFlag={props.firstLoadFlag} />
      <div className={styles.timelinecomponent}>
        <div className={styles.tcheader}>
          <h6
            style={{
              textTransform: 'uppercase',
              color: '#5E6C84',
              marginTop: '5px',
              marginRight: '83%'
            }}
          >
            Timeline Chart
          </h6>
          <div className={styles.morebutton}>
            <DropdownMenu
              triggerButtonProps={{ iconBefore: <MoreIcon label="more" /> }}
              triggerType="button"
            >
              <DropdownItemGroup>
                <DropdownItem onClick={exceldownload}>
                  <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div>
                      <MediaServicesPdfIcon />
                    </div>
                    <div style={{ marginLeft: '2px', marginTop: '3px' }}>
                      Report
                    </div>
                  </div>
                </DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          </div>
        </div>
        <div className={styles.chart}>
          <Suspense
            fallback={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '220px'
                }}
              >
                <ErrorBoundary>
                  <Spinner size="large" />
                </ErrorBoundary>
              </div>
            }
          >
            <div>
              {TimelineChart.loading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '220px'
                  }}
                >
                  <Spinner size="large" />
                </div>
              ) : TimelineChart.data.data.length ? (
                <AnalyzeWidgetWrapper>
                  <Chartplusbox openDrilldownView={setDrillDownData} />
                </AnalyzeWidgetWrapper>
              ) : (
                <div>Empty chart data</div>
              )}
            </div>
          </Suspense>
        </div>
        {/* <div className={styles.button}>
          <Button onClick={openModal}>
            Open modal
          </Button>
        </div> */}
      </div>
      <div>
        <ModalTransition>
          {isOpen && (
            <Modal onClose={Modalclose} height={900} width={1000}>
              <ModalHeader>
                <ModalTitle>Full Data View</ModalTitle>
                <Button appearance="link" onClick={Modalclose}>
                  <CrossIcon label="Close Modal" primaryColor={N500} />
                </Button>
              </ModalHeader>
              <ModalBody style={{ overflow: 'auto', height: '60%' }}>
                <FullDataView style={{ height: '100%' }} data={drillDownData} />
              </ModalBody>
              <ModalFooter>
                {/* <Button>Secondary Action</Button> */}
                {/* <Button appearance="primary" onClick={closeModal} autoFocus>
                  Close
                </Button> */}
              </ModalFooter>
            </Modal>
          )}
        </ModalTransition>
      </div>

      <div className={styles.components}>
        <div className={styles.tables}>
          <div className={styles.tablerow1}>
            {<MostPopularContent table_data={PopularContent} />}
          </div>
          <div className={styles.tablerow2}>
            {<MostReaderTable table_data={ActiveReader} />}
          </div>
        </div>
        <div>
          <MostActiveContibutor table_data={ActiveContributores} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
