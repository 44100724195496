import React from 'react';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import AvatarItem from '@atlaskit/avatar/dist/cjs/components/AvatarItem';
import moment from 'moment';
import { timeFormat, timeParse } from 'd3-time-format';

//table syntax creation for pageview table
export const createPagetableSyntax = (
  values,
  buttonClick,
  userDetails,
  userHeaderId,
  Sourcevalue
) => {
  let tablearray = [];
  values.map(item => {
    let id = item[1];
    const displayName = userDetails[id]?.displayName;
    if (Sourcevalue === 'Confluence')
      tablearray.push({
        cells: [
          {
            key: item[0],
            content: item[0],
            isSortable: true
          },
          {
            key: item[1],
            content: displayName,
            isSortable: true
          },
          {
            key: item[2],
            content: item[2] ? moment(item[2]).fromNow() : null,
            isSortable: true
          },
          {
            key: item[3],
            content: item[3] ? moment(item[3]).fromNow() : null,
            isSortable: true
          },
          {
            key: item[4],
            content: item[4] ? moment(item[4]).fromNow() : null,
            isSortable: true
          },
          {
            key: item[5],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[7], 6)}>
                {item[5]}
              </Button>
            )
          },
          {
            key: item[6],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[7], 7)}>
                {item[6]}
              </Button>
            )
          }
        ],
        key: item[7]
      });
    else if (Sourcevalue === 'JSM Customer Portal')
      tablearray.push({
        cells: [
          {
            key: item[0],
            content: item[0],
            isSortable: true
          },
          {
            key: item[1],
            content: displayName,
            isSortable: true
          },
          {
            key: item[3],
            content: item[3] ? moment(item[3]).fromNow() : null,
            isSortable: true
          },
          {
            key: item[7],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[6], 8)}>
                {item[7]}
              </Button>
            )
          },
          {
            key: item[4],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[6], 6)}>
                {item[4]}
              </Button>
            )
          },
          {
            key: item[5],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[6], 7)}>
                {item[5]}
              </Button>
            )
          }
        ],
        key: item[6]
      });
  });
  return tablearray;
};
//table syntax creation for pageview dialog table
export const createPageDialogSyntax = (
  values,
  userDetails,
  userHeaderId,
  Sourcevalue,
  baseURL
) => {
  let tablearray = [];
  values.map(item => {
    let id = item[userHeaderId];
    if (Sourcevalue === 'Confluence') {
      tablearray.push({
        cells: [
          {
            key: item[1],
            content: (
              <AvatarItem
                avatar={
                  <Avatar
                    name="Tooltip"
                    enableTooltip="true"
                    size="small"
                    src={userDetails[id]?.imageURL}
                  />
                }
                primaryText={userDetails[id]?.displayName}
                enableTextTruncate="true"
              />
            ),
            isSortable: true
          },
          {
            key: item[2],
            content: item[2] ? (
              <div className="tooltip">
                <div className="tooltippointer"></div>
                <div className="tooltiptext">
                  {moment(item[2]).format('DD/MM/YYYY')}
                </div>
                {moment(item[2]).fromNow()}
              </div>
            ) : null
          }
        ],
        key: item[0]
      });
    }
    if (Sourcevalue === 'JSM Customer Portal') {
      tablearray.push({
        cells: [
          {
            key: item[1],
            content: (
              <AvatarItem
                avatar={
                  <Avatar
                    name="Tooltip"
                    enableTooltip="true"
                    size="small"
                    src={'test'}
                  />
                }
                // primaryText={userDetails[id]?.displayName}
                primaryText={item[3]}
                enableTextTruncate="true"
              />
            ),
            isSortable: true
          },
          {
            key: item[2],
            content: item[2] ? (
              <div className="tooltip">
                <div className="tooltippointer"></div>
                <div className="tooltiptext">
                  {moment(item[2]).format('DD/MM/YYYY')}
                </div>
                {moment(item[2]).fromNow()}
              </div>
            ) : null
          },
          {
            key: item[5],
            content: <p>{item[5]}</p>,
            isSortable: true
          },
          {
            key: item[6],
            content: (
              <a
                href={`${baseURL}/servicedesk/customer/portal/${item[4]}/${item[6]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item[6]}
              </a>
            ),
            isSortable: true
          }
        ],
        key: item[0]
      });
    }
  });
  return tablearray;
};
//table syntax creation for user table
export const createUserTableSyntax = (
  values,
  buttonClick,
  userDetails,
  userHeaderId,
  Sourcevalue
) => {
  let tablearray = [];
  values.map(item => {
    let id = item[userHeaderId];
    if (Sourcevalue === 'Confluence') {
      tablearray.push({
        cells: [
          {
            key: item[2],
            content: (
              <div>
                <AvatarItem
                  avatar={
                    <Avatar
                      name="large as"
                      enableTooltip="true"
                      size="small"
                      src={userDetails[id]?.imageURL}
                    />
                  }
                  primaryText={userDetails[id]?.displayName}
                  enableTextTruncate="true"
                ></AvatarItem>
              </div>
            ),
            isSortable: true
          },
          {
            key: item[3],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[0], 3)}>
                {item[3]}
              </Button>
            ),
            isSortable: true
          },
          {
            key: item[4],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[0], 4)}>
                {item[4]}
              </Button>
            ),
            isSortable: true
          },
          {
            key: item[5],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[0], 5)}>
                {item[5]}
              </Button>
            ),
            isSortable: true
          }
        ],

        key: item[0]
      });
    } else if (Sourcevalue === 'JSM Customer Portal') {
      tablearray.push({
        cells: [
          {
            key: item[2],
            content: (
              <div>
                <AvatarItem
                  avatar={
                    <Avatar
                      name="large as"
                      enableTooltip="true"
                      size="small"
                      // src={userDetails[id]?.imageURL}
                      src={''}
                    />
                  }
                  // primaryText={userDetails[id]?.displayName}
                  primaryText={item[4]}
                  enableTextTruncate="true"
                ></AvatarItem>
              </div>
            ),
            isSortable: true
          },
          {
            key: item[3],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[0], 5)}>
                {item[3]}
              </Button>
            ),
            isSortable: true
          },
          {
            key: item[5],
            content: (
              <Button appearance="link" onClick={() => buttonClick(item[0], 7)}>
                {item[5]}
              </Button>
            ),
            isSortable: true
          }
        ],

        key: item[0]
      });
    }
  });

  return tablearray;
};
//table synatsx creation foe user dialog table
//<div className="tooltiptext">{moment(item[1]).format('DD/MM/YYYY')}</div>
export const createUserdialogSyntax = (values, Sourcevalue, baseURL, colId) => {
  let tablearray = [];
  values.map(item => {
    // console.log("tiem in dev", item);
    if (Sourcevalue === 'Confluence') {
      tablearray.push({
        cells: [
          { key: item[0], content: item[0] },
          {
            key: item[1],
            content: item[1] ? (
              <div className="tooltip">
                <div className="tooltippointer"></div>
                <div className="tooltiptext">
                  {moment(item[1]).format('DD/MM/YYYY')}
                </div>
                {moment(item[1]).fromNow()}
              </div>
            ) : null
          }
        ],
        key: item[1]
      });
    }
    if (Sourcevalue === 'JSM Customer Portal' && colId === 5) {
      tablearray.push({
        cells: [
          { key: item[0], content: item[0] },
          {
            key: item[1],
            content: item[1] ? (
              <div className="tooltip">
                <div className="tooltippointer"></div>
                <div className="tooltiptext">
                  {moment(item[1]).format('DD/MM/YYYY')}
                </div>
                {moment(item[1]).fromNow()}
              </div>
            ) : null
          },
          {
            key: item[3],
            content: <p>{item[3]}</p>,
            isSortable: true
          },
          {
            key: item[4],
            content: (
              <a
                href={`${baseURL}/servicedesk/customer/portal/${item[2]}/${item[4]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item[4]}
              </a>
            ),
            isSortable: true
          }
        ],
        key: item[0]
      });
    }
    if (
      Sourcevalue === 'JSM Customer Portal' &&
      colId === 7 &&
      item.length === 4
    ) {
      tablearray.push({
        cells: [
          { key: item[0], content: item[0] },
          {
            key: item[2],
            content: <p>{item[2]}</p>,
            isSortable: true
          },
          {
            key: item[3],
            content: (
              <a
                href={`${baseURL}/servicedesk/customer/portal/${item[1]}/${item[3]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item[3]}
              </a>
            ),
            isSortable: true
          }
        ],
        key: item[0]
      });
    }
  });
  return tablearray;
};

//chartplus syntax creation
export const GeneralChartSyntax = (data, granularity) => {
  //transform the raw data into chartplus syntax
  // console.log('In General chart syntax raw data is', data);
  let monthFormat = timeFormat('%Y-%m');
  let weekFormat = timeFormat('%Y-%W');
  let dayFormat = timeFormat('%Y-%m-%d');
  let monthReformat = timeParse('%Y-%m');
  let weekReformat = timeParse('%Y-%W');
  let tmpFormat, tmpReformat;
  let tmpXaxis = {};

  if (granularity === 'Month') {
    tmpFormat = monthFormat;
    tmpReformat = monthReformat;
  } else if (granularity === 'Week') {
    tmpFormat = weekFormat;
    tmpReformat = weekReformat;
  }

  let Series = [];
  if (granularity === 'Day') {
    tmpXaxis = {
      type: 'date',
      startDate: data.keys[0],
      endDate: data.keys[1]
    };
    Series = data.legends.map(legend => {
      let filtereddata = data.data.filter(filteritem => {
        if (legend in filteritem.values) return filteritem;
      });
      let formatteddata = filtereddata.map(item => {
        return { x: item.key, y: Math.trunc(item.values[legend]) };
      });
      let color = 'black';
      if (legend === 'created') color = 'green';
      else if (legend === 'updated') color = 'orange';
      else if (legend === 'deleted') color = 'red';
      else if (legend === 'viewed') color = 'blue';
      return { name: legend, color: color, data: formatteddata };
    });
  } else {
    tmpXaxis = {
      type: 'date',
      startDate: dayFormat(tmpReformat(data.keys[0])),
      endDate: dayFormat(tmpReformat(data.keys[1]))
    };
    Series = data.legends.map(legend => {
      let filtereddata = data.data.filter(filteritem => {
        if (legend in filteritem.values) return filteritem;
      });
      let formatteddata = filtereddata.map(item => {
        // console.log(
        //   'Inside tablesyntax for week/month',
        //   dayFormat(tmpReformat(item.key)),
        //   item.key
        // );

        return {
          x: dayFormat(tmpReformat(item.key)),
          y: Math.trunc(item.values[legend])
        };
      });
      let color = 'black';
      if (legend === 'created') color = 'green';
      else if (legend === 'updated') color = 'orange';
      else if (legend === 'deleted') color = 'red';
      else if (legend === 'viewed') color = 'blue';
      return { name: legend, color: color, data: formatteddata };
    });
  }
  // console.log('series data in syntax', Series);
  const syntax = {
    container: 'container',
    type: 'line',
    granularity: granularity.toLowerCase(),
    percentage: true,
    stack: true,
    analyzeFlag: true,
    xAxis: { ...tmpXaxis },
    yAxis: {},
    legend: {
      align: 'right-top',
      layout: 'horizontal'
    },
    series: Series,
    tooltip: {
      actions: [
        {
          name: 'dataView',
          icon: 'table-icon',
          color: '#34a7df'
        }
      ]
    }
  };
  return syntax;
};
export const ChartSyntax = data => {
  //tranfrom the raw data into chartplus syntax
  let Series = data.legends.map(legend => {
    let filtereddata = data.data.filter(filteritem => {
      if (legend in filteritem.values) return filteritem;
    });
    let formatteddata = filtereddata.map(item => {
      return { x: item.key, y: Math.trunc(item.values[legend]) };
    });
    let color = 'black';
    if (legend === 'created') color = 'green';
    else if (legend === 'updated') color = 'orange';
    else if (legend === 'deleted') color = 'red';
    else if (legend === 'viewed') color = 'blue';
    return { name: legend, color: color, data: formatteddata };
  });
  // console.log('series data in syntax', Series);
  const syntax = {
    container: 'container',
    type: 'line',
    percentage: true,
    stack: true,
    xAxis: {
      type: 'date',
      startDate: data.keys[0],
      endDate: data.keys[1]
    },
    yAxis: {},
    legend: {
      align: 'right-top',
      layout: 'horizontal'
    },
    series: Series,
    tooltip: {}
  };
  return syntax;
};
