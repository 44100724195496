import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { useRecoilState } from 'recoil';
import FilterAtom from '../../Recoil/Atoms/FilterAtom';

export const UsersTable = props => {
  const [filterData, setFilterData] = useRecoilState(FilterAtom);
  const Sourcevalue = filterData.source_value;
  let rows = props.values;
  // console.log("ROWS",rows)

  const table = {
    head: {
      cells: [
        {
          key: 'head key1',
          content: 'User',
          isSortable: true
        },
        {
          key: 'head key2',
          content: 'Created',
          isSortable: true
        },
        {
          key: 'head key3',
          content: 'Updated',
          isSortable: true
        },
        {
          key: 'head key4',
          content: 'Viewed',
          isSortable: true
        }
      ]
    }
  };

  const JSMtable = {
    head: {
      cells: [
        {
          key: 'head key1',
          content: 'User',
          isSortable: true
        },
        {
          key: 'head key2',
          content: 'Viewed',
          isSortable: true
        },
        {
          key: 'head key3',
          content: 'Requests',
          isSortable: true
        }
      ]
    }
  };

  return (
    <div style={{ margin: '6px' }}>
      <DynamicTable
        head={Sourcevalue === 'Confluence' ? table.head : JSMtable.head}
        rows={rows}
        rowsPerPage={10}
        page={1}
        loadingSpinnerSize="large"
        isLoading={props.loading}
        isFixedSize
        sortKey="term"
        sortOrder="DESC"
        onSort={() => console.log('onSort')}
        onSetPage={() => console.log('onSetPage')}
        emptyView={
          Sourcevalue === 'Confluence' ? (
            <h6>No matching records found</h6>
          ) : (
            <h6>No page views registered with the specified filter.</h6>
          )
        }
      />
    </div>
  );
};
